import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import axiosInstance from "../api/axiosInstance";


const SelectBoxB = ({
  value = [],
  name,
  label,
  placeHolder = "Select",
  onChange = () => {},
  align = "top",
  disabled = false,
  multi = false,
  searchable = false,
  className = "w-full px-4 py-2 rounded-md text-xs border border-secondary bg-offWhite",
  containerClassName = "gap-2",
}) => {
  const id = label?.replace(" ", "-").toLowerCase();
  const [showMenu, setShowMenu] = useState(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState(value); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState(""); // Stores the value entered in the search input
  const [options, setOptions] = useState([]); // Dynamically loaded options
  const searchRef = useRef(); // Reference to the search input element
  const inputRef = useRef(); // Reference to the custom select input element

  useEffect(() => {
    if (!searchable || searchValue.trim() === "") return;

    const fetchSearchResults = async () => {
      try {
        const response = await axiosInstance.get(
          `/getSearchDataSecond?query=${searchValue}`
        );
        setOptions(response.data?.variants || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    const timeout = setTimeout(() => fetchSearchResults(), 300); // Debounce API calls
    return () => clearTimeout(timeout);
  }, [searchValue, searchable]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    } else if (multi) {
      return (
        <div className="flex flex-wrap gap-2">
          {selectedValue.map((val, index) => {
            const option = options.find((opt) => opt.value === val);
            return (
              <div
                key={`${val}-${index}`}
                className="flex gap-1 items-center bg-primeRed text-white text-xs font-medium px-2 py-1 rounded"
              >
                {option?.label}
                {!disabled && (
                  <button onClick={(e) => onTagRemove(e, val)}>
                    <IoMdClose size={14} />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    const option = options.find((opt) => opt.value === selectedValue);
    return option ? option?.label : placeHolder;
  };

  const removeOption = (optionValue) => {
    return selectedValue.filter((val) => val !== optionValue);
  };

  const onTagRemove = (e, optionValue) => {
    e.stopPropagation();
    const newValue = removeOption(optionValue);
    setSelectedValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  const onItemClick = (option) => {
    if (disabled) return;

    let newValue;
    if (multi) {
      if (selectedValue.includes(option.value)) {
        newValue = removeOption(option.value);
      } else {
        newValue = [...selectedValue, option.value];
      }
    } else {
      newValue = option.value;
    }
    setSelectedValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  const isSelected = (option) => {
    if (multi) {
      return selectedValue.includes(option.value);
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div
      className={`flex items ${
        align === "top" ? "flex-col" : "items-center gap-4"
      } ${containerClassName}`}
    >
      {label && align !== "right" ? (
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm flex-shrink-0"
        >
          {label}
        </label>
      ) : (
        <></>
      )}

      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={`relative flex justify-between items-center gap-4 select-none ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${className}`}
      >
        <div
          className={`flex-1 ${
            !selectedValue || selectedValue.length === 0 ? "text-primary" : ""
          }`}
        >
          {getDisplay()}
        </div>

        <IoIosArrowDown
          size={16}
          className={`${showMenu ? "rotate-180" : ""} transition-transform`}
        />

        {showMenu && !disabled && (
          <div className="absolute right-0 left-0 bottom-0 translate-y-full w-full z-10 bg-offWhite border border-secondary rounded-md max-h-80 overflow-auto">
            {searchable && (
              <div className="relative px-4 py-2">
                <input
                  type="search"
                  className="w-full pl-8 pr-2 py-1 border border-secondary text-xs rounded-md outline-none "
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                  disabled={disabled}
                />
                <IoSearch className="absolute left-6 top-1/2 -translate-y-1/2 text-secondary" />
              </div>
            )}

            <ul>
              {options.map((option) => (
                <li
                  key={option.value}
                  onClick={() => onItemClick(option)}
                  className={`text-xs px-4 py-1 cursor-pointer transition-colors duration-200 ${
                    isSelected(option)
                      ? "bg-mainRose text-black"
                      : "hover:bg-lightGray"
                  }`}
                >
                  {option?.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {label && align === "right" ? (
        <label
          htmlFor={id}
          className="capitalize font-medium text-sm flex-shrink-0"
        >
          {label}
        </label>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectBoxB;
