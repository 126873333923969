import FileInput from "../../components/FileInput";
import InputBox from "../../components/InputBox";
import useForm from "../../hooks/useForm";
import Button from "../../components/Button";
import useDeal from "../../hooks/useDeal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../contexts/toastContext";
import { BsImageAlt } from "react-icons/bs";
import { useDealOfTheDayById } from "../../services/dealServices";
import axiosInstance from "../../api/axiosInstance";
import SelectBoxA from "../../components/SelectBoxA";
import SearchableDropdown from "../../components/SearchableDropdown";

const DealOfTheDayForm = ({ edit = false }) => {
  const { dealId } = useParams();
  const { data: deal, isLoading, error } = useDealOfTheDayById(dealId);
  const { addDeal, editDeal } = useDeal("dealOfTheDay", dealId);
  const toast = useToast();
  const navigate = useNavigate();

  // State Variables
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // Keep search input state clean
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (dealData) => {
    const { banner } = dealData;
    try {
      if (edit) {
        await editDeal(dealId, { ...dealData, banner: banner[0] });
        toast.success("Deal updated successfully");
        navigate(-1);
      } else {
        await addDeal({ ...dealData, banner: banner[0] });
        toast.success("Deal created successfully");
        clearForm();
      }
    } catch (err) {
      console.error("Submission Error:", err);
      toast.error("Failed to save deal. Please try again.");
    }
  };

  const { formData, setFormData, handleChange, handleSubmit, clearForm } =
    useForm({}, onSubmitHandler);

  // Populate form data if editing
  useEffect(() => {
    if (edit && deal) {
      setFormData({
        title: deal.title || "",
        banner: deal.banner || null,
        productId: deal.productId?._id || "",
      });
      if (deal.banner) setSelectedImage(deal.banner);
    }
  }, [edit, deal, setFormData]);

  // Handle file input changes
  const handleFileChange = (event) => {
    const image = event.target.files[0];
    if (image) setSelectedImage(URL.createObjectURL(image));
    else setSelectedImage(null);
    handleChange(event);
  };

  // Search function for product dropdown
 

  if (isLoading || (edit && !deal)) {
    return (
      <div className="w-full text-center mt-8">
        <h2 className="text-xl font-medium">Loading...</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full text-center mt-8">
        <h2 className="text-xl font-medium text-red-500">
          {error.message || "Something went wrong!"}
        </h2>
      </div>
    );
  }

  return (
    <section className={`space-y-4 ${edit && "p-4"}`}>
      <h1 className="text-3xl font-medium">
        {edit ? "Edit Deal of the Day" : "Add Deal of the Day"}
      </h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-pop p-8 flex justify-between"
      >
        {/* Left Section */}
        <div className="w-[45%] space-y-4">
          <InputBox
            label="Title"
            name="title"
            value={formData.title || ""}
            onChange={handleChange}
          />

          <FileInput
            label="Upload Image (2:1 ratio)"
            name="banner"
            onChange={handleFileChange}
            align="top"
            dynamic
            button
            containerClassName="gap-2"
          />

          <SearchableDropdown
            label="Product"
            name="productId"
            value={formData.productId}
            placeholder="Search products..."
            onChange={handleChange}
          />
        </div>

        {/* Right Section */}
        <div className="w-[50%] flex flex-col items-end gap-16">
          <div className="flex flex-col items-center gap-4">
            <div className="w-80 h-40 bg-lightGray flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="deal-logo"
                  className="w-full h-full object-cover"
                />
              ) : (
                <BsImageAlt size={64} className="text-primary" />
              )}
            </div>
            <p className="text-sm font-medium">Dimensions: 800 x 400 pixels</p>
          </div>

          <div className="flex gap-8">
            <Button
              type="button"
              onClick={clearForm}
              reverse
              disabled={loading}
            >
              Reset
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default DealOfTheDayForm;
