import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import axiosInstance from "../api/axiosInstance";

const SearchableDropdown = ({
  label,
  name,
  value = [],
  placeholder = "Search...",
  onChange,
  multi = false,
  disabled = false,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (query) => {
    setSearchValue(query);
    if (!query) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.get(`/getSearchDataSecond?query=${query}`);
      setSearchResults(response.data?.variants || []);
    } catch (error) {
      console.error("Search Error:", error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (selectedItem) => {
    if (multi) {
      // Avoid duplicates in multi-select
      if (!value.includes(selectedItem._id)) {
        onChange({ target: { name, value: [...value, selectedItem._id] } });
      }
    } else {
      onChange({ target: { name, value: selectedItem._id } });
      setSearchValue(selectedItem.name);
    }
    setSearchResults([]);
  };

  const handleRemoveTag = (e, val) => {
    e.stopPropagation();
    const updatedValue = value.filter((item) => item !== val);
    onChange({ target: { name, value: updatedValue } });
  };

  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
      </label>
      <div className="relative">
        {multi && (
          <div className="flex flex-wrap gap-2 mb-2">
            {value.map((val, index) => {
              const option = searchResults.find((opt) => opt._id === val) || {};
              return (
                <div
                  key={`${val}-${index}`}
                  className="flex items-center gap-1 bg-indigo-500 text-white text-xs font-medium px-2 py-1 rounded"
                >
                  {option.name || "Loading..."}
                  {!disabled && (
                    <button onClick={(e) => handleRemoveTag(e, val)}>
                      <IoMdClose size={14} />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <input
          type="text"
          id={name}
          name={name}
          placeholder={placeholder}
          value={multi ? "" : searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          className="block w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm:text-xs pl-5 h-8"
          disabled={disabled}
        />
      </div>
      {searchResults.length > 0 && (
        <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
          {loading ? (
            <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
          ) : (
            searchResults.map((item) => (
              <div
                key={item._id}
                className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelect(item)}
              >
                {item.name}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
